import type { DataProvider, GetListResponse } from '@pankod/refine-core'
import { useQueryClient } from '@tanstack/react-query'
import { useDataProvider } from 'src/adapters/HTTPClient'
import { applyCommands } from 'src/commandStore/applyCommands'
import type { Command, Uuid } from 'src/commandStore/types'
import { useEvent } from 'src/libs/useEvent'

import { computeCockpitWorkerTasks } from './computeCockpitWorkerTasks'
import type { TimeSpentEvent } from './types'
import { TubeEnum, useWorker } from './useWorker'

export type CockpitTask = {
  id: Uuid
  timestamp: number
  payload: {
    operation: Operation<TimeSpentEvent>
    relatedCommandIds: Command['id'][]
  }
}

type Operation<T> = OperationCreate<T> | OperationUpdate<T> | OperationDelete

type OperationCreate<T> = {
  type: 'create'
  resource: string
  variables: T
}

export type OperationUpdate<T> = {
  type: 'update'
  resource: string
  id: string
  variables: Partial<T>
}
type OperationDelete = {
  type: 'deleteOne'
  resource: string
  id: string
}

type CockpitWorkerParams = {
  deleteCommands: (commandIds: string[]) => void
  queryKey: any[]
  commandList: Command[]
}

export function useCockpitWorker(params: CockpitWorkerParams) {
  const { deleteCommands, queryKey, commandList } = params

  const queryClient = useQueryClient()
  const dataProvider = useDataProvider()
  const schedule = useWorker<CockpitTask>(
    TubeEnum.cockpitTimeSpentEvents,

    async (job) => {
      await syncCockpitEvents(dataProvider, job.data)
      const { relatedCommandIds } = job.data.payload
      queryClient.setQueryData(
        queryKey,
        (oldState?: GetListResponse<TimeSpentEvent>) => {
          const eventsMap = Object.fromEntries(
            (oldState?.data ?? []).map((event) => [event.id, event]),
          )
          const updatedEvents = applyCommands(
            eventsMap,
            commandList.filter((command) =>
              relatedCommandIds.includes(command.id),
            ),
          )
          const data = Object.values(updatedEvents)
          return {
            data,
            total: data.length,
          }
        },
      )

      await queryClient.invalidateQueries(queryKey)
      deleteCommands(relatedCommandIds)
    },
  )

  return useEvent((commands: Command[]) => {
    const tasks = computeCockpitWorkerTasks(
      commands.filter((command) => !handledCommandIds.has(command.id)),
    )

    tasks.forEach((item) => {
      schedule(item)
      item.payload.relatedCommandIds.forEach((id) => {
        handledCommandIds.add(id)
      })
    })
  })
}
const handledCommandIds = new Set<string>()

async function syncCockpitEvents(
  dataProvider: DataProvider,
  task: CockpitTask,
) {
  console.log('run task', task)
  const { operation } = task.payload
  if (operation.type === 'create') {
    return await dataProvider.create<TimeSpentEvent>(operation)
  }
  if (operation.type === 'update') {
    return await dataProvider.update<TimeSpentEvent>(operation)
  }
  if (operation.type === 'deleteOne') {
    return await dataProvider.deleteOne<TimeSpentEvent>(operation)
  }

  throw Error(
    `Unhandled operation type ${JSON.stringify((operation as any).type)}`,
  )
}
