import { config } from './config'

type Props = {
  className?: string
}

export function Version(props: Props) {
  const { className } = props

  return (
    <p className={className}>
      {config.APP_RELEASE} -{' '}
      <a
        href={`${
          config.REPOSITORY_URL
        }/-/issues/new?issue[description]=${encodeURIComponent(
          `

---

Version : ${config.APP_RELEASE}`,
        )}`}
        target="_blank"
        rel="noreferrer"
      >
        ouvrir un ticket
      </a>
    </p>
  )
}
