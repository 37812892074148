import { formatDuration, addDays } from 'date-fns'
import { differenceInMinutes } from 'date-fns/fp'
import type { AugmentedTimeSpendEventMap } from 'src/App'
import { getIssueColor } from 'src/Issue/getIssueColor'

import type { SchedulerEvent } from './Scheduler/types'

export function toSchedulerEvents(
  events: AugmentedTimeSpendEventMap,
): SchedulerEvent[] {
  const dailyDurations: Record<string, number> = {}

  const list = Object.values(events)
    .filter((event) => event.startDate && event.endDate) // prevents potential corrupted values
    .map((event): SchedulerEvent => {
      const color = getIssueColor(event.gitlabIssue ?? undefined)

      const duration = differenceInMinutes(
        new Date(event.startDate),
        new Date(event.endDate),
      )
      const day = new Date(event.startDate).toDateString()
      dailyDurations[day] = (dailyDurations[day] ?? 0) + duration

      function getText() {
        if (event.name) return event.name
        if (event.gitlabIssue && 'title' in event.gitlabIssue) {
          return event.gitlabIssue.title
        }
      }

      return {
        id: event.id,
        text: getText() ?? '-',
        color,
        start_date: event.startDate,
        end_date: event.endDate,

        metaData: {
          gitlabIssue: event.gitlabIssue,
        },
      }
    })

  const dailyEvents = Object.entries(dailyDurations).map(
    ([dateString, minutes]): SchedulerEvent => {
      const start = new Date(dateString)

      return {
        id: `total-${start.toDateString()}`,
        start_date: start,
        end_date: addDays(start, 1),
        text: formatDuration({
          hours: Math.floor(minutes / 60),
          minutes: minutes % 60,
        }),
      }
    },
  )

  return [...list, ...dailyEvents]
}
