import { Input } from '@chakra-ui/react'
import type { IssueSchema } from '@gitbeaker/core/dist/types/types'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useHttpClient } from 'src/adapters/HTTPClient'
import { Issue } from 'src/Issue'

import './styles.css'

type Props = {
  projectId?: number
}

export function IssuesPicker(props: Props) {
  const { projectId } = props
  const [query, setQuery] = useState<string>()

  return (
    <>
      <label className="search-input-wrapper">
        Ticket
        <Input
          placeholder={projectId ? 'tous' : 'qui me sont assignés'}
          type="text"
          onInput={(event) => setQuery((event.target as any).value)}
        />
      </label>
      <IssuesList project={projectId} query={query} />
    </>
  )
}

type IssuesListProps = {
  project?: number
  query?: string
}

// https://docs.gitlab.com/ee/api/issues.html
function IssuesList(props: IssuesListProps) {
  const { project, query } = props
  const httpClient = useHttpClient()

  const searchEnabled = (query || '').length > 2
  const scope = searchEnabled || project ? 'all' : 'assigned_to_me'

  const { isLoading, isError, data } = useQuery<IssueSchema[]>(
    ['/issues', project, searchEnabled, query, scope],
    async () => {
      const { data: issues } = await httpClient.get<IssueSchema[]>(
        project ? `/projects/${project}/issues` : '/issues',
        {
          params: {
            state: 'opened',
            scope,
            search: searchEnabled ? query : undefined,
            per_page: 100,
          },
        },
      )

      return issues
    },
    {},
  )

  if (isLoading) {
    return <ul className="issues-list">Chargement…</ul>
  }

  if (isError) {
    return <ul className="issues-list">Une erreur est survenue</ul>
  }

  return (
    <ul className="issues-list">
      {!data?.length && 'Aucun résultat'}
      {data?.map((issue) => (
        <Issue
          key={issue.id}
          issue={issue}
          compact
          draggable
          onDragStart={(event) => {
            const partialIssue = {
              id: issue.id,
              iid: issue.iid,
              project_id: issue.project_id,
            }
            event.nativeEvent.dataTransfer?.setData(
              'application/json',
              JSON.stringify(partialIssue),
            )
          }}
        />
      ))}
    </ul>
  )
}
