import type { DataProvider } from '@pankod/refine-core'
import type { AxiosError, AxiosInstance } from 'axios'
import axios from 'axios'
import { createContext, useContext } from 'react'

type Params = {
  getAccessToken: () => string | undefined
  baseURL: string
  onInvalidCredentials: () => void
}

export function HTTPClient(params: Params) {
  const { getAccessToken, baseURL, onInvalidCredentials } = params
  const httpClient = axios.create({ baseURL })

  httpClient.interceptors.request.use((config) => {
    const token = getAccessToken()
    if (!token) {
      return config
    }

    return {
      ...config,
      headers: {
        ...config.headers,
        'private-token': token,
      },
    }
  })

  httpClient.interceptors.response.use(
    (response) => {
      return response
    },
    (error: AxiosError) => {
      if (error.response?.status === 401) onInvalidCredentials()
      throw error
    },
  )

  return httpClient
}

export const HttpClientContext = createContext<AxiosInstance | undefined>(
  undefined,
)

export function useHttpClient(): AxiosInstance {
  const httpClient = useContext(HttpClientContext)

  if (!httpClient) throw new Error('Please provide an HttpClient')

  return httpClient
}

type CockpitParams = {
  getAccessToken: () => string | undefined
  baseURL: string
  auth: { username: string; password: string }
}

export function CockpitHTTPClient(params: CockpitParams) {
  const { baseURL, auth, getAccessToken } = params
  const httpClient = axios.create({ baseURL })

  httpClient.interceptors.request.use((config) => {
    const token = getAccessToken()

    config = { ...config, auth }

    if (!token || config.method?.toUpperCase() === 'GET') {
      return config
    }

    return {
      ...config,
      headers: {
        ...config.headers,
        'x-gitlab-token': token,
      },
    }
  })

  httpClient.interceptors.response.use((response) => {
    return response
  })

  return httpClient
}

export const DataProviderContext = createContext<DataProvider | undefined>(
  undefined,
)

export function useDataProvider() {
  const dataProvider = useContext(DataProviderContext)

  if (!dataProvider) throw new Error('Please provide a DataProvider')

  return dataProvider
}
