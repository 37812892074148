import { setMinutes, addHours, addMinutes } from 'date-fns/fp'
import type {
  SchedulerEventName,
  SchedulerCallback,
  SchedulerConfigOptions,
} from 'dhtmlx-scheduler'
import { useRef, useEffect, useLayoutEffect, useCallback } from 'react'
import type { DragEvent } from 'react'
import * as uuid from 'uuid'

import { scheduler } from './dhtmlxScheduler'
import type { SchedulerEvent, EventIdentifier } from './types'
import './styles.css'

export * from './types'

type SchedulerProps = {
  className?: string
  events: SchedulerEvent[]
  onDragEnd?: (
    schedulerEvent: SchedulerEvent | undefined,
    source: 'move' | 'new-size',
    browserEvent: MouseEvent,
  ) => void
  onEventDeleted?: (schedulerEvent: EventIdentifier, event: MouseEvent) => void
  onEventAdded?: (
    schedulerEvent: SchedulerEvent,
    source: 'external',
    event: DragEvent,
  ) => void
  onDblClick?: (schedulerEvent: SchedulerEvent, event: MouseEvent) => void
}

export function Scheduler(props: SchedulerProps) {
  const { className, events } = props
  const { onDragEnd, onEventDeleted, onEventAdded, onDblClick } = props

  const schedulerRef = useScheduler()
  useUpdateEventsStore(events)

  useAttachEvent(
    'onDragEnd',
    useCallback(
      (id: number, source: any, browserEvent: any) =>
        onDragEnd?.(scheduler.getEvent(id), source, browserEvent),
      [onDragEnd],
    ),
  )

  useAttachEvent(
    'onEventDeleted',
    useCallback(
      (id: number, browserEvent: any) => onEventDeleted?.(id, browserEvent),
      [onEventDeleted],
    ),
  )

  useAttachEvent(
    'onEventAdded',
    useCallback(
      (id: number, source: any, browserEvent: any) =>
        onEventAdded?.(scheduler.getEvent(id), source, browserEvent),
      [onEventAdded],
    ),
  )

  useAttachEvent(
    'onDblClick',
    useCallback(
      (id: number, browserEvent: any) => {
        onDblClick?.(scheduler.getEvent(id), browserEvent)
      },
      [onDblClick],
    ),
  )

  return (
    <div className={className}>
      <div
        ref={schedulerRef}
        style={{ width: '100%', height: '100%' }}
        onDragOver={(event) => {
          // required to denote this component is droppable
          event.preventDefault()
        }}
        onDrop={(browserEvent) => {
          const actionData: { date: Date; section?: any } =
            scheduler.getActionData(browserEvent as unknown as Event)
          const startDate = addMinutes(
            -30,
            roundToNearestQuarter(actionData.date),
          )

          onEventAdded?.(
            {
              id: uuid.v4(),
              start_date: startDate,
              end_date: addHours(1, startDate),
              text: 'New event',
            },
            'external',
            browserEvent,
          )
        }}
      />
    </div>
  )
}

function roundToNearestQuarter(date: Date) {
  const minutes = date.getMinutes()
  return setMinutes(Math.round(minutes / 15) * 15, date)
}

function useScheduler() {
  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (ref.current) {
      const schedulerConfig: Partial<SchedulerConfigOptions> = {
        header: ['day', 'week', 'month', 'date', 'prev', 'today', 'next'],
        first_hour: 0,
        last_hour: 24,
        scroll_hour: 8,
        hour_size_px: 88, // must be multiple of 44
      }

      scheduler.skin = 'material'
      Object.assign(scheduler.config, schedulerConfig)
      scheduler.init(ref.current, new Date())
    }
  }, [])

  return ref
}

function useUpdateEventsStore(events: SchedulerEvent[]) {
  useEffect(() => {
    scheduler.clearAll()
    scheduler.parse(events)
  }, [events])
}

function useAttachEvent(name: SchedulerEventName, handler?: SchedulerCallback) {
  useEffect(() => {
    if (!handler) return

    const eventHandle = scheduler.attachEvent(name, handler)
    return () => {
      scheduler.detachEvent(eventHandle)
    }
  }, [name, handler])
}
