import { useQuery } from '@tanstack/react-query'
import { startOfWeek, sub } from 'date-fns'

import { useMemo } from 'react'

import { useDataProvider } from 'src/adapters/HTTPClient'
import { useIdentity } from 'src/adapters/Identity'
import type { TimeSpentEventsMap } from 'src/App'
import { useCommandStore } from 'src/commandStore'
import { applyCommands } from 'src/commandStore/applyCommands'
import type { Command } from 'src/commandStore/types'

import type { TimeSpentEvent } from './types'
import { useSync } from './useSync'

export type CommandStore<T> = {
  state: T
  dispatch: (command: Command) => void
}

export type RootStore = CommandStore<TimeSpentEventsMap>

export function useRootStore(): RootStore {
  const { events, queryKey } = useTimeSpentEvents()
  const { commandList, addCommand, setCommandsStatus, deleteCommands } =
    useCommandStore()

  const localTimeSpentEvents = useMemo(() => {
    return applyCommands(events ?? {}, commandList)
  }, [events, commandList])

  useSync(commandList, {
    debounceTime: 10 * 1000,
    setCommandsStatus,
    deleteCommands,
    queryKey,
    commandList,
  })

  return useMemo(() => {
    return {
      state: localTimeSpentEvents,
      dispatch: addCommand,
    }
  }, [localTimeSpentEvents, addCommand])
}

function useTimeSpentEvents() {
  const dataProvider = useDataProvider()
  const me = useIdentity()
  const now = new Date()
  const past = sub(startOfWeek(now, { weekStartsOn: 1 }), { weeks: 4 })
  const queryKey = ['cockpit', 'TimeSpentEvent', 'getList', me]
  const { data: events } = useQuery({
    queryKey,
    async queryFn() {
      const response = await dataProvider.getList<TimeSpentEvent>({
        resource: 'time_spent_events',
        filters: [
          { field: 'gitlabUserId', operator: 'eq', value: me?.id },
          { field: 'startDate', operator: 'gte', value: past },
        ],
      })
      return response
    },
    enabled: Boolean(me?.id),
    select(response) {
      return Object.fromEntries(response.data.map((item) => [item.id, item]))
    },
    keepPreviousData: true,
  })
  return { events: events ?? {}, queryKey }
}
