import { createContext, useContext } from 'react'
import { storageFactory } from 'storage-factory'

export function Storage(): Storage {
  return storageFactory(() => localStorage)
}

export const StorageContext = createContext<Storage>(Storage())

export function useStorage(): Storage {
  return useContext(StorageContext)
}
