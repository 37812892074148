import type { UserExtendedSchema } from '@gitbeaker/core/dist/types/types'
import { useQuery } from '@tanstack/react-query'

import { useHttpClient } from './HTTPClient'

export function useIdentity() {
  const httpClient = useHttpClient()
  const { data } = useQuery<UserExtendedSchema>(['/user'], async () => {
    const { data: me } = await httpClient.get<UserExtendedSchema>('/user')
    return me
  })
  return data
}
