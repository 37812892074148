import { useRef, useEffect, useCallback } from 'react'
import { Hustle } from 'src/libs/hustle'
import type { QueueItem, Consumer, PutQueueOptions } from 'src/libs/hustle'

export enum TubeEnum {
  gitlabTimeSpent = 'gitlabTimeSpent',
  cockpitTimeSpentEvents = 'cockpitTimeSpentEvents',
}
const hustle = Hustle({
  tubes: [TubeEnum.gitlabTimeSpent, TubeEnum.cockpitTimeSpentEvents],
  db_version: 2,
})

type WorkFunction<T> = (job: QueueItem<T>) => Promise<void>

const usedTubes = new Set<TubeEnum>()

export function useWorker<T>(
  tube: TubeEnum,
  workFunction: (job: QueueItem<T>) => Promise<void>,
) {
  const workFunctionRef = useRef(workFunction)
  workFunctionRef.current = workFunction

  useEffect(() => {
    if (usedTubes.has(tube)) {
      throw Error(
        `The tube ${JSON.stringify(tube)} is already used by another worker.`,
      )
    }
    usedTubes.add(tube)
    return () => {
      usedTubes.delete(tube)
    }
  }, [tube])

  useEffect(() => {
    let stopped = false
    let consumer: Consumer

    const onOnline = () => consumer?.start()
    const onOffline = () => consumer?.stop()

    window.addEventListener('online', onOnline)
    window.addEventListener('offline', onOffline)

    async function work() {
      if (stopped) return

      if (!hustle.is_open()) {
        await hustle.open()
      }

      const run = withBackOff<T>((job) => workFunctionRef.current(job))
      consumer = hustle.Queue.Consumer<T>(
        async (job) => {
          consumer.stop()
          await run(job)
          consumer.start()
        },
        {
          tube,
          enable_fn() {
            return navigator.onLine
          },
        },
      )

      await hustle.Queue.rescue_reserved_items({})
    }

    work()

    return () => {
      stopped = true
      consumer?.stop()

      window.removeEventListener('online', onOnline)
      window.removeEventListener('offline', onOffline)
    }
  }, [tube])

  return useCallback(
    function scheduleJob(item: T, options?: Omit<PutQueueOptions, 'tube'>) {
      return hustle.Queue.put(item, { ...options, tube })
    },
    [tube],
  )
}

function withBackOff<T>(func: WorkFunction<T>): WorkFunction<T> {
  const maximumBackoff = 12 * 60 * 60 * 1000
  const maximumRetries = 20

  return async function workFunction(job) {
    try {
      await func(job)
      await hustle.Queue.delete(job.id)
    } catch (error) {
      console.error(error)

      if (job.releases > maximumRetries) {
        await hustle.Queue.bury(job.id, {})
      } else {
        const randomSec = Math.round(Math.random() * 3)
        const delay = Math.min(2 ** job.releases + randomSec, maximumBackoff)
        await hustle.Queue.release(job.id, { delay })
      }
    }
  }
}
