import type { ProjectSchema } from '@gitbeaker/core/dist/types/types'
import Minisearch from 'minisearch'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Hint } from 'react-autocomplete-hint'
import { config } from 'src/config'
import { useProjects } from 'src/useProjects'

import createLocalStorageHook from 'use-local-storage-state'

import './styles.css'

const useRecentProjects = createLocalStorageHook<number[]>(
  config.RECENT_PROJECTS_STORAGE_KEY,
  { defaultValue: [] },
)

type ProjectPickerProps = {
  value?: number
  onChange: (projectId?: number) => void
}

export function ProjectPicker(props: ProjectPickerProps) {
  const { onChange } = props

  const [recent, setRecent] = useRecentProjects()
  const [focused, setFocused] = useState(false)
  const [search, setSearch] = useState('')

  const inputRef = useRef<HTMLInputElement>(null)
  const { data } = useProjects()
  const indexed = useMemo(() => {
    return Object.values(data ?? {}).map((project) => {
      const { id } = project
      const displayName = getDisplayName(project)
      const [client, clientProject, scope, name] = displayName.split(' / ')
      return {
        client,
        clientProject,
        scope,
        name,
        id,
      }
    })
  }, [data])

  const setValue = useCallback(
    (projectId?: number) => {
      if (projectId === undefined) {
        setSearch('')
        onChange(undefined)
        inputRef.current?.focus()
      } else {
        onChange(projectId)
        const project = data?.[projectId]
        setSearch(project ? getDisplayName(project) : '')
        setTimeout(() => {
          inputRef.current?.blur()
        })

        setRecent((prev) => {
          return Array.from(new Set([projectId, ...prev])).slice(0, 5)
        })
      }
    },
    [onChange, data, setRecent],
  )

  const minisearch = useMemo(() => {
    const instance = new Minisearch({
      fields: ['client', 'clientProject', 'scope', 'name'],
      storeFields: ['id', 'displayName'],
      searchOptions: {
        boost: { clientProject: 2.5, scope: 3, name: 2 },
        fuzzy: 0.1,
      },
    })
    instance.addAll(indexed)
    return instance
  }, [indexed])

  const results = useMemo(() => {
    if (!search)
      return {
        projects: recent.map((id) => data?.[id]).filter(Boolean),
        suggestions: [],
      }

    const suggestions = minisearch.autoSuggest(search)
    return {
      projects: minisearch
        .search(suggestions[0]?.suggestion ?? search)
        .map((result) => {
          return data?.[result.id]
        }),
      suggestions,
    }
  }, [minisearch, search, recent, data])

  return (
    <label className="search-input-wrapper auto-complete">
      Projet
      <div className="input">
        <Hint options={results.suggestions.map((item) => item.suggestion)}>
          <input
            className="hint-input"
            ref={inputRef}
            placeholder="tous les projets"
            type="text"
            value={search}
            onChange={(event) => setSearch((event.target as any).value)}
            onFocus={() => setFocused(true)}
            onBlur={() => {
              setTimeout(() => {
                if (document.activeElement !== inputRef.current) {
                  setFocused(false)
                }
              }, 400)
            }}
          />
        </Hint>

        {search && (
          <button
            type="button"
            onClick={() => {
              setValue(undefined)
            }}
          >
            x
          </button>
        )}
      </div>
      {focused && (
        <ul className="suggestions">
          {results.projects.slice(0, 5).map((project) => {
            const { id } = project!
            return (
              <li
                tabIndex={0}
                key={id}
                onClick={() => {
                  setValue(id)
                }}
              >
                {getDisplayName(project!)}
              </li>
            )
          })}
        </ul>
      )}
    </label>
  )
}

function getDisplayName(project: ProjectSchema) {
  const { name_with_namespace } = project
  return name_with_namespace.replace(/^Nartex \/ /, '')
}
