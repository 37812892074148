import parentJson from '../../../package.json'
import packageJson from '../package.json'

export const config = {
  APP_VERSION: parentJson.version,
  APP_NAME: packageJson.name,
  APP_DISPLAY_NAME: packageJson.displayName,
  APP_RELEASE: `${packageJson.name}@${parentJson.version}` as const,
  REPOSITORY_URL: packageJson.repository.url,

  RECENT_PROJECTS_STORAGE_KEY: 'nx_recent_projects',

  features: {} as const,
} as const
