import type { BaseRecord, DataProvider } from '@pankod/refine-core'
import type {
  ApiTimeSpentEvent,
  CreateApiTimeSpentEvent,
  TimeSpentEvent,
} from 'src/eventsStore/types'

export type GetListResponse<TData = BaseRecord> = {
  data: TData[]
  total: number
}

export function antiCorruptionLayerProxy(dataProvider: DataProvider) {
  const proxies = Proxies(dataProvider)
  return new Proxy(dataProvider, {
    get(_, methodName: keyof DataProvider) {
      if (typeof methodName !== 'string') return dataProvider[methodName]
      return function wrappedMethod(params: any) {
        if (!params) return (dataProvider[methodName] as any)(params)

        const { resource } = params
        if (resource in proxies) {
          // @ts-ignore
          const proxy = proxies[resource]
          if (proxy && methodName in proxy) {
            // @ts-ignore
            return proxy[methodName](params)
          }
        }

        return (dataProvider[methodName] as any)(params)
      }
    },
  })
}

function Proxies(
  dataProvider: DataProvider,
): Record<string, Partial<DataProvider>> {
  return {
    time_spent_events: TimeSpentEventProxy(dataProvider),
  }
}

function TimeSpentEventProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    async getList(params) {
      const response = await dataProvider.getList<ApiTimeSpentEvent>({
        ...params,
        pagination: {
          pageSize: 1000,
        },
      })
      const transformedData = parseApiEvents(response.data)
      return {
        ...response,
        data: transformedData as any,
      }
    },
    async update(params) {
      const { variables } = params
      return await dataProvider.update({
        ...params,
        variables: encodeApiEvent(variables as unknown as TimeSpentEvent),
      })
    },
    async create(params) {
      const { variables } = params
      return await dataProvider.create({
        ...params,
        variables: encodeApiEvent(variables as unknown as TimeSpentEvent),
      })
    },
  }
}

function parseApiEvents(data: ApiTimeSpentEvent[]): TimeSpentEvent[] {
  return data.map((rawData) => {
    if (
      rawData.gitlabIssueId &&
      rawData.gitlabIssueIid &&
      rawData.gitlabProjectId
    ) {
      return {
        ...rawData,
        startDate: new Date(rawData.startDate),
        endDate: new Date(rawData.endDate),
        gitlabIssue: {
          id: rawData.gitlabIssueId,
          iid: rawData.gitlabIssueIid,
          project_id: rawData.gitlabProjectId,
        },
      }
    }
    return {
      ...rawData,
      startDate: new Date(rawData.startDate),
      endDate: new Date(rawData.endDate),
    }
  })
}

function encodeApiEvent(event: TimeSpentEvent): CreateApiTimeSpentEvent {
  const { gitlabIssue, id, ...rest } = event

  return {
    ...rest,
    newId: id,
    gitlabIssueId: gitlabIssue === null ? null : gitlabIssue?.id,
    gitlabIssueIid: gitlabIssue === null ? null : gitlabIssue?.iid,
    gitlabProjectId: gitlabIssue === null ? null : gitlabIssue?.project_id,
  }
}
