import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useStorage } from 'src/adapters/Storage'
import superjson from 'superjson'

export function usePersistence<T>(storageKey: string, defaultValue: T) {
  const storage = useStorage()

  return [
    useCallback(
      function read(): T {
        const value = storage.getItem(storageKey)
        if (!value) {
          return defaultValue
        }
        try {
          return superjson.parse(value)
        } catch {
          return defaultValue
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [storageKey, storage],
    ),
    useCallback(
      function write(value: T) {
        storage.setItem(storageKey, superjson.stringify(value))
      },
      [storageKey, storage],
    ),
  ] as const
}

export function usePersistedRef<T>(key: string, initialState: T) {
  const [read, write] = usePersistence<T>(key, initialState)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const restored = useMemo(() => read(), [])
  const ref = useRef<T>(restored)

  return [
    useCallback(() => {
      return ref.current
    }, []),
    useCallback(
      (value: T) => {
        ref.current = value
        write(value)
      },
      [write],
    ),
  ] as const
}

export function useLocalStorageState<T>(storageKey: string, defaultValue: T) {
  const [read, write] = usePersistence<T>(storageKey, defaultValue)

  const [state, setState] = useState<T>(read)

  useEffect(() => {
    write(state)
  }, [state, write])

  return [state, setState] as const
}
