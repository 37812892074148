import type { AxiosInstance } from 'axios'
import { useCallback } from 'react'
import { useHttpClient } from 'src/adapters/HTTPClient'
import type { Uuid, Command, TimeSpentDiff } from 'src/commandStore/types'
import * as uuid from 'uuid'

import { TubeEnum, useWorker } from './useWorker'

type GitlabTimeSpentTask = {
  id: Uuid
  timestamp: number
  payload: {
    timeSpentDiff: TimeSpentDiff
    relatedCommandIds: Command['id'][]
  }
}

export function useGitlabWorker() {
  const httpClient = useHttpClient()
  const schedule = useWorker<GitlabTimeSpentTask>(
    TubeEnum.gitlabTimeSpent,
    async (job) => {
      await syncIssueTimeSpent(httpClient, job.data)
    },
  )

  return useCallback(
    (commands: Command[]) => {
      const tasks = groupByIssue(commands)
      tasks.forEach((item) => schedule(item))
    },
    [schedule],
  )
}

async function syncIssueTimeSpent(
  httpClient: AxiosInstance,
  issueDiff: GitlabTimeSpentTask,
) {
  const {
    payload: { timeSpentDiff },
  } = issueDiff
  const { gitlabIssue } = timeSpentDiff
  return await httpClient.post(
    `/projects/${gitlabIssue!.project_id}/issues/${
      gitlabIssue!.iid
    }/add_spent_time`,
    {},
    {
      params: {
        duration: `${timeSpentDiff.deltaInMinutes}m`, // duration in minutes, eg : 16m
        summary: 'nx-calendar bot',
      },
    },
  )
}

function groupByIssue(commands: Command[]): GitlabTimeSpentTask[] {
  const filteredCommands = commands.filter(
    (command) => command.status === 'created' && command.payload.timeSpentDiffs,
  )

  const timeSpentDiffByIssue: Record<string, GitlabTimeSpentTask['payload']> =
    {}

  filteredCommands.forEach((command) => {
    command.payload.timeSpentDiffs?.forEach((diff) => {
      const taskPayload = (timeSpentDiffByIssue[diff.gitlabIssue.id] ??= {
        relatedCommandIds: [],
        timeSpentDiff: {
          gitlabIssue: diff.gitlabIssue,
          deltaInMinutes: 0,
        },
      })

      taskPayload.relatedCommandIds.push(command.id)
      taskPayload.timeSpentDiff.deltaInMinutes += diff.deltaInMinutes
    })
  })

  return Object.values(timeSpentDiffByIssue)
    .filter((payload) => payload.timeSpentDiff.deltaInMinutes)
    .map((payload) => {
      return {
        id: uuid.v4(),
        timestamp: Date.now(),
        payload,
      }
    })
}
