import classNames from 'classnames'
import { useState } from 'react'
import { Version } from 'src/Version'

import { IssuesPicker } from './IssuesPicker'
import { ProjectPicker } from './ProjectPicker'

import './styles.css'

type Props = {
  className?: string
}

export function LeftPane(props: Props) {
  const { className } = props
  const [projectId, setProjectId] = useState<number>()

  return (
    <section className={classNames('left-pane', className)}>
      <ProjectPicker onChange={setProjectId} value={projectId} />
      <IssuesPicker projectId={projectId} />

      <Version className="version" />
    </section>
  )
}
