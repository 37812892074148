import classNames from 'classnames'
import { useMemo, useState } from 'react'

import './App.css'
import { CalendarView, useAugmentedEvents } from './CalendarView'
import type { EventIdentifier } from './CalendarView/Scheduler/types'
import type { Command } from './commandStore/types'
import { EventDetails } from './EventDetails'
import type {
  AugmentedTimeSpentEvent,
  TimeSpentEvent,
} from './eventsStore/types'
import { useRootStore } from './eventsStore/useRootStore'
import { LeftPane } from './LeftPane'

export type TimeSpentEventsMap = Record<string, TimeSpentEvent>
export type AugmentedTimeSpendEventMap = Record<string, AugmentedTimeSpentEvent>

export function App() {
  const store = useRootStore()
  const augmentedLocalTimeSpentEvents = useAugmentedEvents(store.state)

  const augmentedStore = useMemo(() => {
    return {
      ...store,
      state: augmentedLocalTimeSpentEvents,
    }
  }, [store, augmentedLocalTimeSpentEvents])

  const [openedId, openEvent] = useState<EventIdentifier | null>(null)
  const openedEventStore = useMemo(() => {
    return {
      state: openedId ? augmentedStore.state[openedId] : null,
      dispatch(command: Omit<Command, 'payload.eventId'>) {
        if (!openedId) return
        augmentedStore.dispatch({
          ...command,
          payload: { ...command.payload, eventId: openedId },
        } as Command)
      },
    }
  }, [augmentedStore, openedId])

  return (
    <div
      className={classNames(
        'app-layout',
        openedEventStore.state ? ' -hasOpenedEvent' : '',
      )}
    >
      <LeftPane className="issues-picker" />
      <CalendarView
        className="scheduler-container"
        onOpen={openEvent}
        store={augmentedStore}
      />
      <EventDetails
        className="issue-modal-container"
        onClose={() => openEvent(null)}
        store={openedEventStore}
      />
    </div>
  )
}
