import type { TimeSpentEvent, EventStatus } from 'src/eventsStore/types'

import type { Command, CommandStatus } from './types'

export function applyCommands(
  events: Record<string, TimeSpentEvent>,
  commands: Command[],
) {
  const eventsMap = { ...events }

  commands.forEach((command) => {
    if (command.type === 'create') {
      const { id } = command.payload.eventValues
      if (eventsMap[id]) {
        eventsMap[id] = { ...eventsMap[id], status: 'conflicting' }
        return
      }

      eventsMap[command.payload.eventValues.id] = command.payload.eventValues
      return
    }

    const event = eventsMap[command.payload.eventId]
    if (!event) return
    if (event.status === 'conflicting') return
    if (event.updatedAt && event.updatedAt > new Date(command.timestamp)) {
      eventsMap[event.id] = { ...event, status: 'conflicting' }
      return
    }

    if (command.type === 'delete') {
      delete eventsMap[event.id]
      return
    }

    eventsMap[event.id] = {
      ...event,
      ...command.payload.eventValues,
      status: getEventStatus(event.status, command.status),
    }
  })

  return eventsMap
}

function getEventStatus(
  currentStatus: EventStatus | undefined,
  commandStatus: CommandStatus,
): EventStatus {
  if (currentStatus === 'conflicting' || currentStatus === 'synchronizing')
    return currentStatus

  if (commandStatus === 'queued') return 'synchronizing'
  return 'edited'
}
