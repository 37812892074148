import { groupBy, isTruthy, sortBy } from 'remeda'
import type { Command } from 'src/commandStore/types'
import * as uuid from 'uuid'

import type { TimeSpentEvent } from './types'
import type { CockpitTask } from './useCockpitWorker'

export function computeCockpitWorkerTasks(commands: Command[]): CockpitTask[] {
  const groups = groupBy(commands, (command) => {
    if (command.type === 'create') {
      return command.payload.eventValues.id
    }
    if (command.type === 'edit') {
      return command.payload.eventId
    }
    if (command.type === 'delete') {
      return command.payload.eventId
    }

    throw Error(
      `Unrecognized command type ${JSON.stringify((command as any).type)}`,
    )
  })

  return Object.entries(groups)
    .map(([eventId, groupedCommands]): CockpitTask | null => {
      const relatedCommandIds = groupedCommands.map((command) => command.id)

      const hasCreate = groupedCommands.some(
        (command) => command.type === 'create',
      )
      const hasDelete = groupedCommands.some(
        (command) => command.type === 'delete',
      )

      // a Create and an Edit command cancel each other
      if (hasCreate && hasDelete) {
        return null
      }

      // a Delete command ignores every other updates
      if (hasDelete) {
        return newCockpitTask({
          operation: {
            type: 'deleteOne',
            resource: 'time_spent_events',
            id: eventId,
          },
          relatedCommandIds,
        })
      }

      // else, we are creating or updating
      const sorted = sortBy(groupedCommands, (command) => command.timestamp)
      const variables: Partial<TimeSpentEvent> = sorted.reduce(
        (result, command) => {
          if (command.type === 'create') {
            return {
              ...command.payload.eventValues,
              ...result,
            }
          }

          if (command.type === 'edit') {
            return {
              ...result,
              ...command.payload.eventValues,
            }
          }

          return result
        },
        {},
      )

      if (hasCreate) {
        return newCockpitTask({
          operation: {
            type: 'create',
            resource: 'time_spent_events',
            variables: variables as TimeSpentEvent,
          },
          relatedCommandIds,
        })
      }

      return newCockpitTask({
        operation: {
          type: 'update',
          resource: 'time_spent_events',
          id: eventId,
          variables,
        },
        relatedCommandIds,
      })
    })
    .filter(isTruthy)
}

function newCockpitTask(payload: CockpitTask['payload']): CockpitTask {
  return {
    id: uuid.v4(),
    timestamp: Date.now(),
    payload,
  }
}
