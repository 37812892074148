import type { IssueSchema } from '@gitbeaker/core/dist/types/types'
import { useQueries } from '@tanstack/react-query'
import classNames from 'classnames'
import { useMemo } from 'react'
import { useHttpClient } from 'src/adapters/HTTPClient'
import { useIdentity } from 'src/adapters/Identity'
import type { AugmentedTimeSpendEventMap, TimeSpentEventsMap } from 'src/App'
import {
  newEditCommand,
  newCreateCommand,
  newDeleteCommand,
} from 'src/commandStore'
import type { CommandStore } from 'src/eventsStore/useRootStore'
import * as uuid from 'uuid'

import type { ConsiseIssueSchema, TimeSpentEvent } from '../eventsStore/types'

import { Scheduler } from './Scheduler'
import { toSchedulerEvents } from './toSchedulerEvents'

type Props = {
  className?: string
  store: CommandStore<AugmentedTimeSpendEventMap>
  onOpen: (eventId: string | null) => void
}

export function CalendarView(props: Props) {
  const { className, store, onOpen } = props
  const { state } = store
  const me = useIdentity()

  const eventsList = useMemo(() => {
    return toSchedulerEvents(state)
  }, [state])

  return (
    <Scheduler
      className={classNames(className)}
      events={eventsList}
      onDragEnd={(event, source) => {
        if (!event) return
        if (!me) return
        const prevEvent = store.state?.[event.id]

        if (!prevEvent) {
          const newEvent: TimeSpentEvent = {
            id: uuid.v4(),
            startDate: event.start_date,
            endDate: event.end_date,
            gitlabUserId: me.id,
            createdAt: new Date(),
          }
          store.dispatch(newCreateCommand(newEvent))
          onOpen(newEvent.id)
          return
        }
        if (
          prevEvent.startDate.getTime() === event.start_date.getTime() &&
          prevEvent.endDate.getTime() === event.end_date.getTime()
        ) {
          return
        }
        if (source === 'move') {
          store.dispatch(
            newEditCommand(prevEvent, {
              startDate: event.start_date,
              endDate: event.end_date,
            }),
          )
          return
        }

        store.dispatch(
          newEditCommand(prevEvent, {
            startDate: event.start_date,
            endDate: event.end_date,
          }),
        )
      }}
      onEventDeleted={(eventId) => {
        const prevEvent = store.state?.[eventId]
        if (!prevEvent) return
        store.dispatch(newDeleteCommand(prevEvent))
      }}
      onEventAdded={(event, source, browserEvent) => {
        if (!me) return
        if (source === 'external') {
          const issue: ConsiseIssueSchema = JSON.parse(
            browserEvent.dataTransfer?.getData('application/json'),
          )

          const newEvent: TimeSpentEvent = {
            id: uuid.v4(),
            startDate: event.start_date,
            endDate: event.end_date,
            gitlabUserId: me.id,
            gitlabIssue: issue,
            createdAt: new Date(),
          }

          store.dispatch(newCreateCommand(newEvent))
        } else {
          const newEvent: TimeSpentEvent = {
            id: uuid.v4(),
            startDate: event.start_date,
            endDate: event.end_date,
            gitlabUserId: me.id,
            gitlabIssue: event.metaData?.gitlabIssue,
            createdAt: new Date(),
          }

          store.dispatch(newCreateCommand(newEvent))
        }
      }}
      onDblClick={(event) => {
        onOpen(String(event.id))
      }}
    />
  )
}

export function useAugmentedEvents(
  events: TimeSpentEventsMap,
): AugmentedTimeSpendEventMap {
  const gitlabIssuesMap = useFullIssuesMap(events)
  const augmentedEvents = Object.fromEntries(
    Object.values(events).map((event) => {
      if (!event.gitlabIssue) return [event.id, event]
      const gitlabIssue = gitlabIssuesMap?.[event.gitlabIssue.id]

      return [
        event.id,
        {
          ...event,
          gitlabIssue,
        },
      ]
    }),
  )
  return augmentedEvents
}

function useFullIssuesMap(eventList: TimeSpentEventsMap) {
  const httpClient = useHttpClient()
  const queries = useMemo(() => {
    return Object.values(eventList)
      .filter((event) => event.gitlabIssue)
      .map((event) => {
        return {
          queryKey: ['gitlab', 'issue', event.gitlabIssue!.id],
          async queryFn() {
            const { data: result } = await httpClient.get<IssueSchema>(
              `/projects/${event.gitlabIssue!.project_id}/issues/${
                event.gitlabIssue!.iid
              }`,
            )
            return result
          },
        }
      })
  }, [httpClient, eventList])

  const results = useQueries({ queries })

  const gitlabIssuesMap = useMemo(() => {
    return Object.fromEntries(
      results
        .filter((result) => result.data !== undefined)
        .map((result) => {
          return [result.data!.id, result.data!] as const
        }),
    )
  }, [results])

  return gitlabIssuesMap
}
