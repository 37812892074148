import 'hustle'

type Args = {
  db_name?: string
  db_version: number
  housekeeping_delay?: number
  message_lifetime?: number
  tubes?: string[]
}

export type QueueItem<T = unknown> = {
  // the item's Hustle-assigned unique id
  id: number

  // the item's priority (lower is more important, defaults to 1024)
  priority: number

  // the item's user-specified data payload
  data: T

  // how old the item is
  age: number

  // how many times this item has been reserved
  reserves: number

  // how many times this item has been released
  releases: number

  // how many times this item has timed out
  timeouts: number

  // how many times this item has been buried
  buries: number

  // how many times this item has been kicked
  kicks: number

  // how many seconds left this job has to run before expiring (and being moved to the ready state)
  time_left: number

  // what state this item is in (set by peek)
  state: 'ready' | 'buried' | 'reserved'

  // when this item was created (new Date().getTime())
  created: number
}

export type PutQueueOptions = {
  tube?: string
  priority?: number
  delay?: number
  ttr?: number
}

type IHustle = {
  open: () => Promise<any>
  close: () => boolean
  is_open: () => boolean
  wipe: () => boolean
  Queue: {
    peek: (item_id: number) => Promise<QueueItem | null>
    put: (job_data: any, options: PutQueueOptions) => Promise<QueueItem>
    reserve: (tube: string) => Promise<QueueItem>
    delete: (item_id: number) => Promise<QueueItem | null>
    release: (
      item_id: number,
      options: { priority?: number; delay?: number },
    ) => Promise<QueueItem | null>
    bury: (
      item_id: number,
      options: { priority?: number },
    ) => Promise<QueueItem | null>
    kick: (amount: number) => Promise<number>
    kick_job: (item_id: number) => Promise<void>
    touch: (item_id: number) => Promise<void>
    count_ready: (tube: string) => Promise<number>
    rescue_reserved_items: (options: {
      maxRescueLimit?: number
      rescueTimeLimitInSeconds?: number
    }) => Promise<void>
    Consumer: <T>(
      consume_fn: (item: QueueItem<T>) => void,
      options: {
        tube: string
        delay?: number
        enable_fn?: () => any
        error?: (error: any) => void
      },
    ) => Consumer
  }
}

export type Consumer = { start: () => void; stop: () => void }

export function Hustle(args: Args): IHustle {
  const instance = new (window as any).Hustle(args)
  instance.promisify()

  return instance
}
