/* eslint-disable react/no-unescaped-entities */
import { Button, Input } from '@chakra-ui/react'
import { DataProvider } from '@nartex/api-platform'
import { useState, useMemo, useRef, useLayoutEffect, useEffect } from 'react'
import type { PropsWithChildren } from 'react'
import { antiCorruptionLayerProxy } from 'src/adapters/antiCorruptionLayerProxy'
import { useEnv } from 'src/adapters/Env'
import {
  HTTPClient,
  HttpClientContext,
  CockpitHTTPClient,
  DataProviderContext,
} from 'src/adapters/HTTPClient'
import './styles.css'
import { useStorage } from 'src/adapters/Storage'
import { Version } from 'src/Version'

const TOKEN_STORAGE_KEY = 'nxcalendar-gitlab-api-token'

export function ApiTokenProvider(props: PropsWithChildren<{}>) {
  const { children } = props
  const env = useEnv()

  const [apiToken, setToken] = useStoredToken()
  const [isValid, setIsValid] = useState(true)

  const httpClient = useMemo(
    () =>
      HTTPClient({
        baseURL: env.API_URL,
        getAccessToken: () => apiToken,
        onInvalidCredentials() {
          alert('Votre jeton gitlab semble invalide')
          setIsValid(false)
        },
      }),

    [env, apiToken],
  )

  const dataProvider = useMemo(() => {
    const cockpitHttpClient = CockpitHTTPClient({
      getAccessToken: () => apiToken,
      baseURL: env.COCKPIT_URL,
      auth: { username: env.COCKPIT_LOGIN, password: env.COCKPIT_JWT },
    })
    return antiCorruptionLayerProxy(
      DataProvider({ httpClient: cockpitHttpClient, apiUrl: env.COCKPIT_URL }),
    )
  }, [env.COCKPIT_URL, env.COCKPIT_LOGIN, env.COCKPIT_JWT, apiToken])

  if (apiToken && isValid) {
    return (
      <DataProviderContext.Provider value={dataProvider}>
        <HttpClientContext.Provider value={httpClient}>
          {children}
        </HttpClientContext.Provider>
      </DataProviderContext.Provider>
    )
  }

  return (
    <section className="apitoken-prompt">
      <Onboarding onSubmit={setToken} />

      <Version className="version" />
    </section>
  )
}

function useStoredToken() {
  const storage = useStorage()

  const [apiToken, setToken] = useState('')
  useLayoutEffect(() => {
    if (apiToken) return

    const token = storage.getItem(TOKEN_STORAGE_KEY)
    if (token) {
      setToken(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    storage.setItem(TOKEN_STORAGE_KEY, apiToken)
  }, [storage, apiToken])

  return [apiToken, setToken] as const
}

type OnboardingProps = {
  onSubmit: (token: string) => void
}

function Onboarding(props: OnboardingProps) {
  const { onSubmit } = props
  const inputRef = useRef<HTMLInputElement>(null)

  const [hasClicked, setCLicked] = useState(false)

  if (!hasClicked) {
    return (
      <div className="content">
        <h2>Bienvenue</h2>
        <p>Pour vous connecter, merci de renseigner votre token Gitlab</p>
        <a
          href="https://projects.nartex.fr/-/profile/personal_access_tokens"
          target="_blank"
          rel="noreferrer"
          className="button-element"
          onClick={() => setCLicked(true)}
        >
          Générer un token avec le scope "API"
        </a>
        <Button
          className="button-as-link"
          type="button"
          onClick={() => setCLicked(true)}
        >
          J'ai déjà un token
        </Button>
      </div>
    )
  }

  return (
    <form
      className="content"
      onSubmit={(event) => {
        onSubmit(inputRef.current?.value || '')
        event.preventDefault()
      }}
    >
      <Button
        className="button-as-link"
        type="button"
        onClick={() => setCLicked(false)}
      >
        {'<'} Retour
      </Button>
      <p>Votre jeton gitlab avec le scope "API"</p>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Input className="input-element" type="text" ref={inputRef} />
        <Button className="button-element" type="submit">
          Ok
        </Button>
      </div>
    </form>
  )
}
