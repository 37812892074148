import { debounce } from 'lodash'
import { useEffect, useMemo } from 'react'
import type { Command, CommandStatus } from 'src/commandStore/types'

import { useCockpitWorker } from './useCockpitWorker'
import { useGitlabWorker } from './useGitlabWorker'

type Milliseconds = number
type Options = {
  debounceTime: Milliseconds
  setCommandsStatus: (commandIds: string[], status: CommandStatus) => void
  deleteCommands: (commandIds: string[]) => void
  queryKey: any[]
  commandList: Command[]
}

export function useSync(commands: Command[], options: Options) {
  const {
    debounceTime,
    setCommandsStatus,
    deleteCommands,
    queryKey,
    commandList,
  } = options

  // keep `useGitlabWorker` for a month then it can be safely removed
  useGitlabWorker()

  const scheduleCockpit = useCockpitWorker({
    deleteCommands,
    queryKey,
    commandList,
  })

  const debouncedSchedule = useMemo(() => {
    return debounce((commandsList: Command[]) => {
      const commandsToSync = commandsList.filter(
        (command) => command.status === 'created',
      )
      if (!commandsToSync.length) return

      scheduleCockpit(commandsToSync)

      setCommandsStatus(
        commandsToSync.map((command) => command.id),
        'queued',
      )
    }, debounceTime)
  }, [debounceTime, scheduleCockpit, setCommandsStatus])

  useEffect(() => {
    debouncedSchedule(commands)
  }, [commands, debouncedSchedule])
}
