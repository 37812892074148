import type { IssueSchema } from '@gitbeaker/core/dist/types/types'
import classNames from 'classnames'
import ellipsize from 'ellipsize'
import type { HTMLAttributes } from 'react'
import { Remark } from 'react-remark'
import type { ConsiseIssueSchema } from 'src/eventsStore/types'

import { useProject } from 'src/useProjects'

import { getIssueColor } from './getIssueColor'
import './styles.css'

type IssueProps = {
  issue: ConsiseIssueSchema | IssueSchema
  compact?: boolean
  draggable?: boolean
} & HTMLAttributes<HTMLElement>

export function Issue(props: IssueProps) {
  const { issue, compact, draggable, ...domProps } = props
  const { project_id, iid } = issue

  const title = 'title' in issue ? issue.title : '-'
  const description = 'description' in issue ? issue.description : '-'
  const web_url = 'web_url' in issue ? issue.web_url : '-'

  const color = getIssueColor(issue)
  const { data: project } = useProject(project_id)

  const projectName = project?.name_with_namespace.replace('Nartex /', '')

  const formatedDescription: string | undefined = description?.replaceAll(
    '#!===============================================================!#',
    '',
  )

  return (
    <article
      {...domProps}
      draggable={draggable}
      className={classNames('issue-item', draggable && '-draggable')}
      style={{ backgroundColor: color, color: 'white', ...domProps.style }}
    >
      <a className="caption" href={web_url} target="_blank" rel="noreferrer">
        {projectName || project_id} — #{iid}
      </a>
      <strong className="title">{title}</strong>
      <div className="content">
        {compact ? (
          ellipsize(formatedDescription ?? '', 100, { ellipse: '…' })
        ) : (
          <Remark>{formatedDescription}</Remark>
        )}
      </div>
    </article>
  )
}
